import request from '@/utils/request'

// 查询项目节点日志列表
export function listProjectNodeLog(query) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/project_node_log/list',
        method: 'get',
        params: query
    })
}

// 查询项目节点日志详细
export function getProjectNodeLog(id) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/project_node_log/' + id,
        method: 'get'
    })
}

// 新增项目节点日志
export function addProjectNodeLog(data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/project_node_log',
        method: 'post',
        data: data
    })
}

// 修改项目节点日志
export function updateProjectNodeLog(data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/project_node_log',
        method: 'put',
        data: data
    })
}

// 删除项目节点日志
export function delProjectNodeLog(id) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/project_node_log/' + id,
        method: 'delete'
    })
}
