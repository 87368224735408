<template>
  <el-form-item v-for="item in contractParams" :label="item.name" :prop="item.key">
    <el-input v-if="item.paramTypeCode === 'input'"
              v-model="paramFormState[item.key]"
              @change="changeInput"
    ></el-input>
  </el-form-item>
</template>

<script>
export default {
  name: "ContractParam",
  props:{
    contractParams:{
      type: Array,
      required: true,
    }
  },
  emits: ['complete'],
  data(){
    return{
      paramFormState:{},
    }
  },
  methods:{
    reset(){
      this.paramFormState = {}
    },
    setFormData(data){
      this.paramFormState = data
    },
    changeInput(val){
      this.$emit('complete',this.paramFormState)
    }
  }
}
</script>

<style scoped>

</style>
