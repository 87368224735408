import request from '@/utils/request'

// 查询项目节点列表
export function listProjectNode(query) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/project_node/list',
        method: 'get',
        params: query
    })
}

// 查询项目节点详细
export function getProjectNode(id) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/project_node/' + id,
        method: 'get'
    })
}

// 新增项目节点
export function addProjectNode(data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/project_node',
        method: 'post',
        data: data
    })
}

// 修改项目节点
export function updateProjectNode(data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/project_node',
        method: 'put',
        data: data
    })
}

// 删除项目节点
export function delProjectNode(id) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/project_node/' + id,
        method: 'delete'
    })
}
