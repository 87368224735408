<template>
  <el-dialog
      v-model="isShow"
      :title="step === 'first' ? '选择合同模版' : '填写合同参数'"
      width="800px"
      :before-close="handleClose"
  >
    <el-table v-if="step === 'first'" :data="contractTemplates" style="width: 100%" height="250">
      <el-table-column width="100">
        <template #default="scope">
          <el-radio :label="scope.row.id" v-model="formState.contractTemplateId" :key="scope.row.id"></el-radio>
        </template>
      </el-table-column>
      <el-table-column prop="label"/>
    </el-table>
    <el-form ref="formRef" :inline="false" label-width="150px" :model="formState.contractParamData">
      <el-form-item v-for="item in contractParams" :label="item.name" :prop="item.key" :key="item.key"
                    :rules="[
                        { required: true, message: '请输入'+item.name, trigger: 'blur',
                          validator: (rule, value, callback) => {
                            if (this.formState.contractParamData[item.key] === '' || this.formState.contractParamData[item.key] === undefined){
                              callback('请输入')
                            }else{
                              callback()
                            }
                          }
                        },
                    ]">
        <el-input v-if="item.paramTypeCode === 'input'"
                  v-model="formState.contractParamData[item.key]"
                  @change="changInput"
                  maxlength="20"
                  :key="item.key"
        ></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
          <span class="dialog-footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button v-if="step === 'first'" type="primary" @click="handleNext">下一步</el-button>
            <el-button v-if="step === 'second'" type="primary" @click="handleBack">上一步</el-button>
            <el-button v-if="step === 'second'" type="primary" @click="handleSubmit">确定</el-button>
          </span>
    </template>
  </el-dialog>
</template>

<script>
import {getContractParams, getContractTemplateOptions} from "@/api/contract_template";
import {isResOK} from "@/api/response";
import ContractParam from "@/components/ContractParam/index.vue";
import {addProjectContract} from "@/api/project_contract";

export default {
  name: "contract",
  components: {ContractParam},
  props: {
    projectId:{
      type: Number,
      required: true,
    },
    projectTypeId: {
      type: Number,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['update:visible', 'completed'],
  data() {
    return {
      formState: {
        contractParamData: {},
        projectId: this.projectId,
      },
      contractTemplates: [],
      contractParams: [],
      step: 'first',
      contractTemplateId: 0,
      submitting: false,
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },

      set(val) {
        this.$emit('update:visible', val)
      },
    },
  },
  created() {
    this.fetchContractTemplateOption()
  },
  methods: {
    handleClose() {
      this.isShow = false
    },
    handleNext() {
      if (this.formState.contractTemplateId === 0 || this.formState.contractTemplateId === undefined) {
        this.$message({
          message: "请选择项目模版",
          type: 'error',
        })
        return
      }
      this.getContractParams()
      this.step = 'second'
      if (this.contractTemplateId !== this.formState.contractTemplateId) {
        this.formState.contractParamData = {}
      }
      // this.$refs.contractParamRef.setFormData(this.formState.contractParamData)
      this.contractTemplateId = this.formState.contractTemplateId
    },
    changInput() {
      this.$refs.formRef.clearValidate();
    },
    handleBack() {
      this.contractParams = []
      this.step = 'first'
    },
    handleSubmit() {
      if (this.submitting) {
        this.$message({
          message: '已经提交，无需重复提交',
          type: 'warning',
        })
        return
      }

      this.submitting = true
      this.$refs.formRef.validate((values, fields) => {
        let isCanSubmit = true
        this.contractParams.forEach(item => {
          if (!this.formState.contractParamData[item.key] || this.formState.contractParamData[item.key] === '') {
            this.submitting = false
            isCanSubmit = false
          }
        })
        if (!isCanSubmit) {
          return
        }
        let operatorId = ''
        let operatorName = ''
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (userInfo !== undefined){
          operatorId = userInfo.id
          operatorName = userInfo.name
        }
        addProjectContract(Object.assign({}, this.formState, { operatingMode: 'web', operatorId: operatorId, operatorName: operatorName })).then((res) => {
          if (isResOK(res)) {
            this.$message({
              message: '合同新增成功',
              type: 'success',
            })
            // 关闭模态框
            this.isShow = false
            // 告知父组件已完成
            this.$emit('completed')
          }
          this.submitting = false
        }).finally(() => {
          this.submitting = false
        })
      })
      this.submitting = false

    },
    getContractParams() {
      getContractParams(this.formState.contractTemplateId).then(res => {
        if (isResOK(res)) {
          this.contractParams = res.data
        }
      })
    },
    fetchContractTemplateOption() {
      getContractTemplateOptions(this.projectTypeId).then((res) => {
        if (isResOK(res)) {
          this.contractTemplates = res.data
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
