<template>
  <el-form-item v-for="item in surveyParams" :label="item.name" :prop="item.key" >
    <el-input v-if="item.paramTypeCode === 'input'" v-model="paramFormState[item.key]" @change="changeInput" style="width:80%"></el-input>
    <el-select v-if="item.paramTypeCode === 'select'" v-model="paramFormState[item.key]" :placeholder="'请选择'+item.name" @change="changeSelect" style="width:80%">
      <el-option
          v-for="op in item.options"
          :key="op.value"
          :label="op.label"
          :value="op.value"
      />
    </el-select>
    <el-radio-group v-if="item.paramTypeCode === 'radio'" v-model="paramFormState[item.key]" @change="changeRadio" style="width:80%">
      <el-radio v-for="op in item.options" :label="op.value"  >{{op.label}}</el-radio>
    </el-radio-group>
    <el-checkbox-group v-if="item.paramTypeCode === 'checkbox'" v-model="paramFormState[item.key]" @change="changeCheckBoxGroup" style="width:80%">
      <el-checkbox v-for="(op,index) in item.options" :label="op.value" :key="index">{{ op.label }}</el-checkbox>
    </el-checkbox-group>
    <file-upload v-if="item.paramTypeCode === 'picture'" :value="paramFormState[item.key]" :limit="4" :is-show-tip="true" @update:file-list="(fileList) => {handleUploadFile(item.key,fileList)}" :file-type="fileTypes"></file-upload>
  </el-form-item>
</template>

<script>
import FileUpload from "@/components/FileUpload/index.vue";

export default {
  name: "SurveyParam",
  components: {FileUpload},
  props:{
    surveyParams:{
      type: Array,
      required: true,
    }
  },
  emits: ['complete'],
  data(){
    return{
      paramFormState:{},
      fileTypes: ['.png','.jpg']
    }
  },
  methods:{
    reset(){
      this.paramFormState = {}
    },
    setFormData(data){
      this.paramFormState = data
    },
    handleUploadFile(key,fileList){
      this.paramFormState[key] = fileList
      this.$emit('complete',this.paramFormState)
    },
    changeRadio(val){
      this.$emit('complete',this.paramFormState)
    },
    changeInput(val){
      this.$emit('complete',this.paramFormState)
    },
    changeSelect(val){
      this.$emit('complete',this.paramFormState)
    },
    changeCheckBoxGroup(val){
      this.$emit('complete',this.paramFormState)
    }
  }
}
</script>

<style scoped>

</style>
