<template>
  <el-dialog
      v-model="isShow"
      title="合同详情"
      width="80%"
      :before-close="handleClose"
  >
    <template #default>
      <el-table :data="data" row-key="id" border height="350" v-loading="loading">
        <el-table-column prop="name" label="合同名称" width="300" />
        <el-table-column prop="statusName" label="合同状态" width="100" />
        <el-table-column label="上传文件详情">
          <template #default="scope">
            <el-row v-for="(item,index) in scope.row.contractList">
              <el-button  :key="index" type="primary" link @click="showContract(item)">{{item.name}}</el-button>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="300">
          <template #default="scope">
            <el-tooltip content="上传合同文件" v-if="'terminated' !== statusCode">
              <el-image style="width: 30px; height: 30px;margin-right:10px;padding-top: 10px" :src="imgUrl" fit="cover" @click="uploadContract(scope.row)" />
            </el-tooltip>

            <el-tooltip content="合同详情">
              <el-icon :size="20" style="margin-right:10px" @click="showContractDemo(scope.row)">
                <Document color="#409EFF"/>
              </el-icon>
            </el-tooltip>
            <el-tooltip content="下载合同原件">
              <el-icon :size="20" style="margin-right:10px" @click="handleDownContractOriginal(scope.row)" >
                <Download color="#409EFF" />
              </el-icon>
            </el-tooltip>
            <el-tooltip content="删除" v-if="'terminated' !== statusCode">
              <el-icon :size="20" style="margin-right:10px" @click="handleDelete(scope.row)">
                <DeleteFilled color="red"/>
              </el-icon>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog
          v-model="innerVisible"
          width="800px"
          :title="title"
          append-to-body
      >
        <el-form ref="formRef" :inline="false" :model="editingProjectContract" label-width="80px">
          <el-form-item label="上传" prop="fileList">
            <file-upload :value="editingProjectContract.contractList" :limit="3" :is-show-tip="true" @update:file-list="handleUploadFile" :file-type="fileTypes"></file-upload>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="submitForm" :disabled="submitting">确定</el-button>
            <el-button @click="handleCloseInner">取消</el-button>
          </span>
        </template>
      </el-dialog>
    </template>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">关闭</el-button>
      </span>
    </template>
  </el-dialog>
  <file-preview :filepath="docUrl" v-if="openDetail" v-model:visible="openDetail"/>
</template>

<script>
import {isResOK} from "@/api/response";
import {delProjectContract, listProjectContract, uploadContractFile} from "@/api/project_contract";
import FileUpload from "@/components/FileUpload/index.vue";
import {getProject } from "@/api/project";
import FilePreview from "@/components/FilePreview/FilePreview.vue";
let baseURL=process.env.VUE_APP_BASE_API
export default {
  name: "ProjectContractIndex",
  components: {FilePreview, FileUpload},
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    projectId: {
      type: Number,
      required: true,
    }
  },
  emits: ['update:visible','completed'],
  data(){
    return {
      submitting: false,
      data: [],
      loading: false,
      openDetail: false,
      fileTypes: ['.doc','.docx','.png','.jpg'],
      innerVisible: false,
      title: '上传合同文件',
      editingProjectContract: {},
      changed: false,
      docUrl: '',
      statusCode:'',
      imgUrl: require(`/src/assets/upload.png`),
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },

      set(val) {
        this.$emit('update:visible', val)
      },
    },
  },
  created() {
    this.getList()
  },
  methods: {
    uploadContract(row){
      // 上传合同文件
      this.editingProjectContract = row
      this.innerVisible = true
    },
    handleUploadFile(fileList){
      this.editingProjectContract.contractList = fileList
    },
    showContract(item){
      //todo 修改文档展示
      this.docUrl = baseURL +item.storageBlob.newFilename;
      this.openDetail = true
    },
    showContractDemo(row){
      this.docUrl = baseURL +row.contractDemoList[0].storageBlob.newFilename;
      this.openDetail = true
    },
    getList(){
      this.loading = true
      listProjectContract({projectId: this.projectId}).then(res => {
        if (isResOK(res)){
          this.data = res.rows
        }
        this.loading = false
      })
      getProject(this.projectId).then(res=>{
        if(isResOK(res)){
          this.statusCode=res.data.statusCode
        }
      })
    },
    handleClose(){
      if (this.changed){
        this.$emit('completed')
      }
      this.isShow = false
    },
    handleDelete(row){
      const  name = row.name
      this.$messageBox.confirm(
          '是否确认删除合同名为"' + name + '"的数据项？',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(() => {
        let operatorId = ''
        let operatorName = ''
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (userInfo !== undefined){
          operatorId = userInfo.id
          operatorName = userInfo.name
        }
        delProjectContract(row.id,  { operatingMode: 'web', operatorId: operatorId, operatorName: operatorName }).then((res) => {
          if (isResOK(res)) {
            this.$message({
              message: '删除成功',
              type: 'success',
            })
          }
          this.changed = true
          this.getList()
        })
      }).catch(() => {})
    },
    handleCloseInner(){
      this.innerVisible = false
      this.reset();
    },
    reset() {
      this.editingProjectContract = {};
      this.resetForm("formRef");
    },
    submitForm(){
      let operatorId = ''
      let operatorName = ''
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (userInfo !== undefined){
        operatorId = userInfo.id
        operatorName = userInfo.name
      }
      this.submitting = true
      //上传文件
      uploadContractFile(Object.assign({}, this.editingProjectContract, {operatorId: operatorId, operatorName: operatorName})).then((res) => {
        if(isResOK(res)){
          this.$message({
            message: '文件上传成功',
            type: 'success',
          })
        }
        this.changed = true
        this.handleCloseInner()
        this.getList()
        this.submitting = false
      }).finally(() => {
        this.submitting = false
      })
    },
    handleDownContractOriginal(row){
      if (row.contractDemoList != null){
        this.$download.name(row.contractDemoList[0].storageBlob.newFilename,false,true)
      }
    },
  },
}
</script>

<style scoped>

</style>
