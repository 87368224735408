<template>
  <div>
    <el-form ref="formRef" :inline="true" :model="formState">
      <el-row>
        <el-col :span="6">
          <el-form-item prop="name" label="项目名称">
            <el-input
              v-model="formState.name"
              placeholder="请输入项目名称"
              clearable
              style="width: 240px"
            /> </el-form-item
        ></el-col>
        <el-col :span="6">
          <el-form-item prop="projectTypeId" label="项目类型">
            <el-select
              v-model="formState.projectTypeId"
              filterable
              clearable
              placeholder="请选择项目类型"
              style="width: 240px"
            >
              <el-option
                v-for="item in productTypeOptions"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              />
            </el-select> </el-form-item
        ></el-col>
        <el-col :span="6">
          <el-form-item prop="creatorName" label=" 创建者 ">
            <el-input
              v-model="formState.creatorName"
              placeholder="请输入创建者"
              clearable
              style="width: 240px"
            /> </el-form-item
        ></el-col>

        <el-col :span="6">
          <el-form-item prop="queryTime" label="创建时间">
            <el-date-picker
              v-model="formState.queryTime"
              type="date"
              placeholder="请选择创建时间"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              style="width: 240px"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="status" label="选择状态">
            <el-select
              v-model="formState.status"
              filterable
              clearable
              placeholder="请选择状态"
              style="width: 240px"
            >
              <el-option
                v-for="item in productStatusOptions"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6"
          ><el-form-item prop="areaId" label="选择区域">
            <el-cascader
              placeholder="请选择区域"
              v-model="areaId"
              :options="areaOptions"
              :props="{
                value: 'orgId',
                label: 'orgName',
                children: 'subOrgList',
                checkStrictly: true,
              }"
              @change="handleChangeArea"
              style="width: 240px"
              clearable
              :disabled="areaDisabled"
            /> </el-form-item
        ></el-col>
        <el-col :span="6"
          ><el-form-item prop="responsibleUserName" label="负责人">
            <el-input
              v-model="formState.responsibleUserName"
              placeholder="请输入负责人"
              clearable
              style="width: 240px"
            /> </el-form-item
        ></el-col>
        <el-col :span="6"
          ><el-form-item>
            <el-button type="primary" @click="handleSearch">搜索</el-button>
            <el-button type="warning" @click="handleReset">刷新</el-button>
          </el-form-item></el-col
        >
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { getProjectTypeOptions } from "@/api/project_type";
import { isResOK, isSysResOK } from "@/api/response";
import { getProjectStatusOptions } from "@/api/project";
import { queryOrgRelTree } from "@/api/system";
import { areaOptions } from "@/api/mock";

export default {
  name: "SearchProject",
  emits: ["submit"],

  data() {
    return {
      formState: {},
      productTypeOptions: [],
      productStatusOptions: [],
      areaOptions: [],
      areaId: [],
      cascaderKey: 0,
      areaDisabled: true
    }
  },
  created() {
    this.getProductTypeOptions();
    this.getProjectStatusOptions();
    this.queryOrgRelTree();
  },
  methods: {
    handleChangeArea(val) {
      if (val !== null && val !== undefined) {
        this.formState.areaId = val.join(",");
      } else {
        this.formState.areaId = undefined;
      }
    },
    queryOrgRelTree() {
      this.areaDisabled = true
      queryOrgRelTree({
        orgId: "1681542405985234945",
        relType: "1",
      }).then((res) => {
        if (isSysResOK(res)) {
          this.areaOptions.push(res.result.orgRelInfoVo);
          this.areaDisabled = false
        }
      });
    },
    getProductTypeOptions() {
      getProjectTypeOptions().then((res) => {
        if (isResOK(res)) {
          this.productTypeOptions = res.data;
        }
      });
    },
    getProjectStatusOptions() {
      getProjectStatusOptions().then((res) => {
        if (isResOK(res)) {
          this.productStatusOptions = res.data;
        }
      });
    },
    handleSearch() {
      this.$emit("submit", this.formState);
    },

    handleReset() {
      this.$refs.formRef.resetFields()
      this.areaId = undefined
      this.cascaderKey = new Date().getTime()
      this.$emit('submit', this.formState)
    },
  },
}
</script>
