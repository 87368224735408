import request from '@/utils/request'

// 查询项目合同列表
export function listProjectContract(query) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/project_contract/list',
        method: 'get',
        params: query
    })
}

// 查询项目合同详细
export function getProjectContract(id) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/project_contract/' + id,
        method: 'get'
    })
}

// 新增项目合同
export function addProjectContract(data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/project_contract',
        method: 'post',
        data: data
    })
}

// 修改项目合同
export function updateProjectContract(data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/project_contract',
        method: 'put',
        data: data
    })
}

// 删除项目合同
export function delProjectContract(id, data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/project_contract/' + id,
        method: 'delete',
        data: data
    })
}

// 上传合同文件
export function uploadContractFile(data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/project_contract/upload',
        method: 'post',
        data: data
    })
}
