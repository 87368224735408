<template>
  <el-dialog
      v-model="isShow"
      title="项目节点"
      width="80%"
      :before-close="handleClose"
  >
    <template #default>
      <el-table :data="data" row-key="id" border height="350" v-loading="loading">
        <el-table-column>
          <template #default="scope">
            <el-row :span="24">
              <el-col :span="12">
                {{ scope.row.name }}
              </el-col>
              <el-col :span="12" style="text-align:right">
                <el-tooltip
                    content="跟踪日志">
                  <el-icon v-if="scope.row.statusCode != 'completed'" @click="addNodeLog(scope.row)">
                    <Document color="#3DC0FF"/>
                  </el-icon>
                </el-tooltip>
              </el-col>
            </el-row>
            <el-row :span="24">
              <el-col :span="8">
               <span style="width: 50px;color:white;border-radius:2px"
                     :style="{'background-color': statusBgColor[scope.row.statusCode]}">
                    {{ scope.row.statusInfo }}
               </span>
              </el-col>
              <el-col :span="8">
                <span>节点描述:</span>{{ scope.row.description }}
              </el-col>
              <el-col :span="8">
                <span>跟踪日志:</span>
                <span>
                  <el-button type="primary" link @click="showNodeLog(scope.row)">{{ scope.row.logCount }}</el-button>
                </span>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog
          v-model="innerVisible"
          width="800px"
          title="跟踪日志"
          append-to-body
      >
        <el-form ref="formRef" :inline="false" :model="projectNodeLog" :rules="rules">
          <el-form-item prop="fileList">
            <el-input :rows="3" type="textarea" maxlength="300" v-model="projectNodeLog.content"></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="submitForm" :disabled="submitting">确定</el-button>
            <el-button @click="handleCloseInner">取消</el-button>
          </span>
        </template>
      </el-dialog>
      <el-dialog
          v-model="innerLogVisible"
          width="800px"
          title="跟踪日志"
          append-to-body
      >
        <el-table :data="projectNodeLogList" row-key="id" border height="350" v-loading="logLoading">
          <el-table-column>
            <template #default="scope">
              <el-row :span="24">
                <el-col :span="12">
                  {{ '日志' + (scope.$index + 1) }}
                </el-col>
                <el-col :span="12" style="text-align:right">
                  {{ scope.row.createTime }}
                </el-col>
              </el-row>
              <el-row :span="24">
                <el-col :span="24">
                  {{ scope.row.content }}
                </el-col>
              </el-row>
            </template>
          </el-table-column>
        </el-table>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="handleCloseLogInner">关闭</el-button>
          </span>
        </template>
      </el-dialog>

    </template>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">关闭</el-button>
      </span>
    </template>
  </el-dialog>

</template>

<script>
import FilePreview from "@/components/FilePreview/FilePreview.vue";
import FileUpload from "@/components/FileUpload/index.vue";
import {isResOK} from "@/api/response";
import {listProjectNode} from "@/api/project_node";
import {Document} from "@element-plus/icons-vue";
import {addProjectNodeLog, listProjectNodeLog} from "@/api/project_node_log";
import {ElMessage} from 'element-plus'

export default {
  name: "ProjectNodeIndex",
  components: {Document, FilePreview, FileUpload},
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    projectId: {
      type: Number,
      required: true,
    },
    nodeStatus: {
      type: String,
      default: '',
    }
  },
  emits: ['update:visible', 'completed'],
  data() {
    return {
      submitting: false,
      data: [],
      loading: false,
      logLoading: false,
      innerVisible: false,//新增跟踪日志
      innerLogVisible: false,//日志列表
      projectNodeLog: {},
      statusBgColor: {
        'no_connection': '#8080FF',
        'communicate': '#409eff',
        'completed': '#039D12',
      },
      rules: {
        content: [
          {required: true, message: '请输入内容', trigger: 'blur'},
          {min: 1, max: 200, message: '不能超过200字', trigger: 'blur'},
        ],
      },
      projectNodeLogList: [],
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },

      set(val) {
        this.$emit('update:visible', val)
      },
    },
  },
  created() {
    this.getList()
  },
  methods: {
    addNodeLog(row) {
      this.projectNodeLog.projectNodeId = row.id
      this.innerVisible = true
      this.submitting = false
    },
    getList() {
      this.loading = true
      listProjectNode({projectId: this.projectId, statusCode: this.nodeStatus}).then(res => {
        if (isResOK(res)) {
          this.data = res.rows
        }
        this.loading = false
      })
    },
    handleClose() {
      if (this.changed) {
        this.$emit('completed')
      }
      this.isShow = false
    },
    handleCloseInner() {
      this.innerVisible = false
      this.reset();
    },
    reset() {
      this.projectNodeLog = {};
      this.resetForm("formRef")
    },
    submitForm() {
      this.submitting = true
      //新增节点
      if (this.projectNodeLog.content) {
        let operatorId = ''
        let operatorName = ''
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (userInfo !== undefined){
          operatorId = userInfo.id
          operatorName = userInfo.name
        }
        addProjectNodeLog({projectNodeId: this.projectNodeLog.projectNodeId, content: this.projectNodeLog.content, operatorId: operatorId, operatorName: operatorName }).then((res) => {
          if (isResOK(res)) {
            this.$message({
              message: '跟踪日志新增成功',
              type: 'success',
            })
          }
          this.handleCloseInner()
          this.getList()
        })
      } else {
        ElMessage({
          message: '跟踪日志不能为空',
          type: 'warning',
        })
        this.submitting = false
      }
    },
    showNodeLog(row) {
      this.logLoading = true
      this.projectNodeLog = []
      //显示节点日志
      let operatorId = ''
      let operatorName = ''
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (userInfo !== undefined){
        operatorId = userInfo.id
        operatorName = userInfo.name
      }
      listProjectNodeLog({projectNodeId: row.id, operatingMode: 'web', operatorId: operatorId, operatorName: operatorName}).then((res) => {
        if (isResOK(res)) {
          this.projectNodeLogList = res.rows
        }
        this.logLoading = false
      })
      this.innerLogVisible = true
    },
    handleCloseLogInner() {
      this.innerLogVisible = false
    }
  },
}
</script>

<style scoped>

</style>
