<template>
  <el-dialog
      v-model="isShow"
      title="调研详情"
      width="800px"
      :before-close="handleClose"
  >
    <el-form ref="formRef" :inline="false" :model="formState" label-width="150px" :disabled="true">
      <survey-param v-show="surveyParams.length > 0" ref="surveyParamRef" :survey-params="surveyParams"></survey-param>
      <el-empty
          v-if="surveyParams.length === 0"
          :image-size="67"
          style=" margin-bottom: 20px"
          description="暂无数据"
      />
    </el-form>
    <template #footer>
          <span class="dialog-footer">
            <el-button @click="handleClose">关闭</el-button>
          </span>
    </template>
  </el-dialog>
</template>

<script>
import SurveyParam from "@/components/SurveyParam/index.vue";
import {getProject} from "@/api/project";
import {isResOK} from "@/api/response";
import {getSurveyParams} from "@/api/survey_template";

export default {
  name: "SurveyParamData",
  components: {SurveyParam},
  props: {
    visible: {
      type: Boolean,
      required: true,
    },

    id: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },

      set(val) {
        this.$emit('update:visible', val)
      },
    },
  },
  data() {
    return {
      formState: {},
      surveyParams: [],
    }
  },
  emits: ['update:visible'],
  created() {
    this.fetchFormData()
  },
  methods: {
    fetchFormData() {
      this.loading = true
      getProject(this.id, {}).then((res) => {
        if (isResOK(res)) {
          this.formState = res.data
          this.getSurveyParams(this.formState.surveyTemplateId)
        }
      }).finally(() => {
        this.loading = false
      })
    },
    getSurveyParams(surveyTemplateId){
      getSurveyParams(surveyTemplateId).then(res => {
        if (isResOK(res)){
          this.surveyParams = res.data
          this.$refs.surveyParamRef.setFormData(this.formState.surveyParamData)
        }
      })
    },
    handleClose() {
      this.isShow = false
    }
  }
}
</script>

<style scoped>

</style>
