<template>
  <div>
    <el-main>
        <search-project @submit="submitSearch" />

        <div class="table-btn-list">
                <el-button
                    class="table-btn"
                    type="primary"
                    @click="showNewModal"
                >
                  新增
                </el-button>
        </div>

      <el-table :data="data" row-key="id" :show-header="false" v-loading="loading">
        <el-table-column width="100">
          <template #default="scope">
            <div style="display:flex;align-items:center;justify-content:center">
              <el-image style="width: 49px; height: 49px;" :src="backImgUrl" fit="contain"/>
              <el-image style="width: 23px; height: 22px;position:absolute" :src="imgUrl" fit="contain"/>
            </div>
            <div style="display:flex;align-items:center;justify-content:center;margin-top: 5px">
              <span style="width: 50px;text-align:center;color:white;border-radius:2px" :style="{'background-color': statusBgColor[scope.row.statusCode]}">
                {{ scope.row.statusName }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column>
          <template #default="scope">
            <el-row :span="24">
              <el-col :span="12" style="font-weight: bold;">
                <span>{{ scope.row.name }}</span>
              </el-col>
              <el-col :span="12" style="text-align:right">
                <el-tooltip content="操作历史">
                  <el-icon :size="20" style="margin-right:10px" @click="showOperationHistoryModal(scope.row)">
                    <Clock />
                  </el-icon>
                </el-tooltip>
                <el-tooltip content="新增合同" v-if="'terminated' !== scope.row.statusCode">
                  <el-icon :size="20" style="margin-right:10px" @click="addContract(scope.row)">
                    <DocumentAdd />
                  </el-icon>
                </el-tooltip>
                <el-tooltip content="取消暂停" v-if="'suspend' === scope.row.statusCode">
                  <el-icon :size="20" style="margin-right:10px" @click="handleCancelSuspend(scope.row)">
                    <Refresh />
                  </el-icon>
                </el-tooltip>
                <el-tooltip content="编辑项目" v-if="'terminated' !== scope.row.statusCode">
                  <el-icon :size="20" style="margin-right:10px" @click="showEditModal(scope.row.id)">
                    <EditPen />
                  </el-icon>
                </el-tooltip>
                <el-tooltip content="恢复项目" v-if="'terminated' === scope.row.statusCode">
                  <el-icon :size="20" style="margin-right:10px" @click="handleRecover(scope.row)">
                    <CircleCheck />
                  </el-icon>
                </el-tooltip>
                <el-tooltip content="暂停项目" v-if="['tracking','signing','signed'].includes(scope.row.statusCode)">
                  <el-icon :size="20" style="margin-right:10px" @click="handleSuspend(scope.row)">
                    <VideoPause />
                  </el-icon>
                </el-tooltip>
                <el-tooltip content="终止项目" v-if="'terminated' !== scope.row.statusCode">
                  <el-icon :size="20" style="margin-right:10px" @click="handleTerminated(scope.row)">
                    <CircleClose />
                  </el-icon>
                </el-tooltip>
                <el-tooltip content="删除项目">
                  <el-icon :size="20" color="#ff0000" @click="handleDelete(scope.row)">
                    <Delete/>
                  </el-icon>
                </el-tooltip>
              </el-col>
            </el-row>
            <el-row :span="24">
              <el-col :span="6">
                <el-descriptions :column="1" :border="true" >
                  <el-descriptions-item label="项目类型：" span="1">{{ scope.row.projectType?.name }}</el-descriptions-item>
                  <el-descriptions-item label="所属区域：" span="1" >{{ scope.row.areaName }}</el-descriptions-item>
                  <el-descriptions-item label="详细地址：" span="1"  >{{ scope.row.address }}</el-descriptions-item>
                </el-descriptions>
              </el-col>
              <el-col :span="6">
                <el-descriptions :column="1" :border="true" >
                  <el-descriptions-item label="创建者：" span="1" >{{ scope.row.creatorName }}</el-descriptions-item>
                  <el-descriptions-item label="负责人：" span="1" >{{ scope.row.responsibleUserName }}</el-descriptions-item>
                  <el-descriptions-item label="创建时间：" span="1" >{{ scope.row.createTime}}</el-descriptions-item>
                  <el-descriptions-item label="节点模版：" span="1">{{ scope.row.nodeTemplate?.name }}</el-descriptions-item>
                </el-descriptions>
              </el-col>
              <el-col :span="6">
                <el-descriptions :column="1" :border="true" >
                  <el-descriptions-item label="节点数量：" span="1" >
                    <a style="color: #409EFF" key="primary" type="primary" link @click="showProjectNodeModal(scope.row,'')">{{ scope.row.nodeCount }}</a>
                  </el-descriptions-item>
                  <el-descriptions-item label="已完成节点：" span="1" >
                    <a style="color: #409EFF" v-if="scope.row.finishedNodeCount > 0" @click="showProjectNodeModal(scope.row,'completed')">{{ scope.row.finishedNodeCount }}</a>
                    <span v-if="scope.row.finishedNodeCount===0">{{ scope.row.finishedNodeCount }}</span>
                  </el-descriptions-item>
                  <el-descriptions-item label="未完成节点：" span="1" >
                    <a style="color: #409EFF" @click="showProjectNodeModal(scope.row,'unfinish')">{{ scope.row.unfinishedNodeCount }}</a>
                  </el-descriptions-item>
                  <el-descriptions-item label="安装任务数量：" span="1" >
                    {{ scope.row.installTaskCount }}
                  </el-descriptions-item>
                </el-descriptions>
              </el-col>
              <el-col :span="6">
                <el-descriptions :column="1" :border="true" >
                  <el-descriptions-item label="调研模版：" span="1"  >{{ scope.row.surveyTemplate?.name }}</el-descriptions-item>
                  <el-descriptions-item label="调研详情：" span="1" >
                    <el-button key="primary" type="primary" link @click="showSurveyParamDataModal(scope.row.id)">点击查看</el-button>
                  </el-descriptions-item>
                  <el-descriptions-item label="合同状态：" span="1" >
                    <el-button key="primary" type="primary" link @click="showProjectContractDetail(scope.row)">{{ scope.row.uploadedProjectContractCount.toString() + '/' + scope.row.projectContractCount.toString() }}</el-button>
                  </el-descriptions-item>
                </el-descriptions>

              </el-col>
            </el-row>
          </template>
        </el-table-column>
      </el-table>

        <pagination
            v-show="total>0"
            :total="total"
            v-model:page="query.pageNum"
            v-model:limit="query.pageSize"
            @pagination="fetchData"
        />

        <!-- 新增 模态框 -->
        <new-project
          v-if="isShowNewModal"
          v-model:visible="isShowNewModal"
          @completed="fetchData"
        />

        <!-- 编辑 模态框 -->
        <edit-project
          v-if="isShowEditModal"
          :id="editingId"
          v-model:visible="isShowEditModal"
          @completed="fetchData"
        />

      <survey-param-data v-if="isShowSurveyParamDataModal"
                         :id="showingId"
                         v-model:visible="isShowSurveyParamDataModal"></survey-param-data>

      <operation-history-list-modal v-if="isShowOperationHistoryModal"
                                    :reference-id="showingOperationHistoryId"
                                    reference-type="Project"
                                    v-model:visible="isShowOperationHistoryModal"/>
      <!-- 新增合同 选择合同模版 -->
      <contract ref="contractRef" v-if="isShowContractSelectModal"
                :project-type-id="projectTypeId"
                :project-id="addContractId"
                v-model:visible="isShowContractSelectModal"
                @completed="changeContractTemplate"/>
      <!-- 合同详情 -->
      <project-contract-index v-if="isShowProjectContractDetail"
                              :project-id="projectId"
                              @completed="this.fetchData"
                              v-model:visible="isShowProjectContractDetail"
      />
      <!-- 合同节点 -->
      <project-node-index v-if="isShowProjectNodeDetail"
                          :project-id="projectId"
                          :node-status="nodeStatus"
                          v-model:visible="isShowProjectNodeDetail"/>
    </el-main>
  </div>
</template>

<script>
import {cancelSuspend, delProject, listProject, recover, suspend, terminated} from '@/api/project'
import { isResOK } from '@/api/response'
import { defineAsyncComponent } from 'vue'
import SearchProject from '@/view/project/Search.vue'
import Pagination from "@/components/Pagination/index.vue";
import SurveyParamData from "@/view/project/SurveyParamData.vue";
import OperationHistoryListModal from "@/view/operation_history/Modal.vue";
import Contract from "@/view/project/Contract.vue";
import ProjectContractIndex from "@/view/project_contract/index.vue";
import ProjectNodeIndex from "@/view/project_node/index.vue";
import store from '@/store'
import { getUrlParams } from "@/api/userList";
import { getCurrentUser } from "@/api/user";

export default {
  name: 'ProjectList',
  components: {
    ProjectNodeIndex,
    ProjectContractIndex,
    Contract,
    OperationHistoryListModal,
    SurveyParamData,
    Pagination,
    SearchProject,
    NewProject: defineAsyncComponent(() => import('@/view/project/New.vue')),
    EditProject: defineAsyncComponent(() => import('@/view/project/Edit.vue')),
  },

  data() {
    return {
      data: [],
      loading: true,
      total: 0,
      bgColor: "#8080FF",
      statusBgColor: {
        'tracking': '#8080FF',
        'signing': '#409eff',
        'signed': '#039D12',
        'suspend': '#F59A23',
        'terminated': '#AAAAAA'
      },
      query: {
       pageNum: 1,
       pageSize: 10,
      },

      sort: {
        sort_field: '',
        sort_order: '',
      },

      isShowNewModal: false, // 是否显示新增表单弹窗
      isShowEditModal: false, // 是否显示编辑表单弹窗
      isShowSurveyParamDataModal: false,
      editingId: 0, // 正在编辑的记录id
      showingId: 0, // 显示调研详情
      imgUrl: require(`/src/assets/project.png`),
      backImgUrl: require(`/src/assets/project_back.svg`),
      isShowOperationHistoryModal: false,
      showingOperationHistoryId: 0,
      isShowContractSelectModal: false,
      isShowContractParamModal: false,
      addContractId: 0,//新增合同
      projectTypeId: 0,
      contractParams:[],
      projectId: 0,// 显示
      isShowProjectContractDetail: false,
      isShowProjectNodeDetail: false,
      nodeStatus: '',// 节点状态
    }
  },

  computed: {},

  created() {
    this.fetchData()
    this.fetchCurrentUser()
  },

  methods: {
    fetchCurrentUser() {
      store.dispatch('user_info/cleanUser')
      let data = {}
      const params = getUrlParams(window.location.href)
      if (params[`accessToken`]) {
        sessionStorage.setItem('token', params[`accessToken`])
        data[`auth-token`] = params[`accessToken`]
        getCurrentUser(data).then(res => {
          if (isResOK(res)) {
            store.dispatch('user_info/setUser', res.data)
          }
        })
      }
    },

    showProjectContractDetail(row){
      // 显示项目合同详情
      this.projectId = row.id
      this.isShowProjectContractDetail = true
    },
    addContract(row){
      console.log(row);
      this.addContractId = row.id
      this.projectTypeId = row.projectTypeId
      this.isShowContractSelectModal = true
    },
    changeContractTemplate(){
      this.fetchData()
    },
    showSurveyParamDataModal(id){
      this.showingId = id
      this.isShowSurveyParamDataModal = true
    },
    showNewModal() {
      this.isShowNewModal = true
    },

    showEditModal(id) {
      this.editingId = id
      this.isShowEditModal = true
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
                                 { pageNum: this.query.pageNum },
                                 search)
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      listProject(Object.assign({}, this.query, this.sort)).then((res) => {
        if (isResOK(res)) {
          this.data = res.rows
          this.total = res.total
          this.loading = false
        }
      }).finally(() => {
        this.loading = false
      })
    },
    handleSuspend(row) {
      // 暂停项目
      const id = row.id
      this.$messageBox.confirm(
          '确定暂停此项目吗？',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(() => {
        let operatorId = ''
        let operatorName = ''
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (userInfo !== undefined){
          operatorId = userInfo.id
          operatorName = userInfo.name
        }
        suspend(id, {operatorId: operatorId, operatorName: operatorName}).then((res) => {
          if (isResOK(res)) {
            this.$message({
              message: '暂停成功',
              type: 'success',
            })
          }
          this.fetchData()
        })
      }).catch(() => {})
    },
    handleTerminated(row) {
      // 暂停项目
      const id = row.id
      this.$messageBox.confirm(
          '确定终止此项目吗？',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(() => {
        let operatorId = ''
        let operatorName = ''
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (userInfo !== undefined){
          operatorId = userInfo.id
          operatorName = userInfo.name
        }
        terminated(id, {operatorId: operatorId, operatorName: operatorName}).then((res) => {
          if (isResOK(res)) {
            this.$message({
              message: '终止成功',
              type: 'success',
            })
          }
          this.fetchData()
        })
      }).catch(() => {})
    },
    handleCancelSuspend(row) {
      // 暂停项目
      const id = row.id
      this.$messageBox.confirm(
          '确定取消暂停吗？',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(() => {
        let operatorId = ''
        let operatorName = ''
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (userInfo !== undefined){
          operatorId = userInfo.id
          operatorName = userInfo.name
        }
        cancelSuspend(id, {operatorId: operatorId, operatorName: operatorName}).then((res) => {
          if (isResOK(res)) {
            this.$message({
              message: '取消暂停成功',
              type: 'success',
            })
          }
          this.fetchData()
        })
      }).catch(() => {})
    },
    handleRecover(row) {
      // 暂停项目
      const id = row.id
      this.$messageBox.confirm(
          '确定恢复此项目吗？',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(() => {
        let operatorId = ''
        let operatorName = ''
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (userInfo !== undefined){
          operatorId = userInfo.id
          operatorName = userInfo.name
        }
        recover(id, {operatorId: operatorId, operatorName: operatorName}).then((res) => {
          if (isResOK(res)) {
            this.$message({
              message: '恢复成功',
              type: 'success',
            })
          }
          this.fetchData()
        })
      }).catch(() => {})
    },
    showOperationHistoryModal(row) {
      this.showingOperationHistoryId = row.id
      this.isShowOperationHistoryModal = true
    },
    handleDelete(row) {
      const name = row.name
      const id =row.id
      this.$messageBox.confirm(
                '是否确认删除项目名为"' + name + '"的数据项？',
                {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning',
                }
            ).then(() => {
        let operatorId = ''
        let operatorName = ''
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (userInfo !== undefined){
          operatorId = userInfo.id
          operatorName = userInfo.name
        }
              delProject(id, { operatingMode: 'web', operatorId: operatorId, operatorName: operatorName }).then((res) => {
                if (isResOK(res)) {
                  this.$message({
                    message: '删除成功',
                    type: 'success',
                  })
                }
                this.fetchData()
              })
            }).catch(() => {})
    },
    showProjectNodeModal(row,nodeStatus){
      this.projectId = row.id
      this.nodeStatus = nodeStatus
      this.isShowProjectNodeDetail = true
    }
  },
}
</script>
<style scoped lang="less">
::v-deep .el-descriptions__label {
  background-color: #FFFFFF!important;
  width: 120px;
  border: 0px!important;
  vertical-align: top;
}
::v-deep .el-descriptions__content {
  border: 0px!important;
}

::v-deep .el-col-6 {
  background-color: var(--el-fill-color-blank);
}
</style>
